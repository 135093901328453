import { Button, Card } from '@equitymultiple/react-eui';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { AccreditationStatus } from 'types/actions/account';

import ProgressBarBorder from '../../../../components/ProgressBarBorder/ProgressBarBorder';
import * as styles from './AccreditationCard.module.scss';

interface Props {
  accountName: string;
  accountReferenceId: number;
  accreditationStatus: AccreditationStatus;
}

const AccreditationCard = ({
  accountReferenceId,
  accountName,
  accreditationStatus
}: Props) => {
  const isPending = accreditationStatus === 'pending';
  const isIncomplete = accreditationStatus === 'incomplete';

  let buttonText = 'Verify Now';
  if (isIncomplete) buttonText = 'Complete Verification';
  let heading = 'Next, Verify Your Accreditation';
  let copy: string | ReactElement =
    `The SEC requires that all investors are verified as accredited, before making an EquityMultiple investment. To finalize your investment you need to provide proof that "${accountName}" is an accredited investor. If you do not verify your accreditation you will decline your position in the investment.`;
  let currentStep = 4;

  if (isPending) {
    heading = "You've completed the accreditation verification process";
    copy = (
      <>
        Thank you for completing the manual accreditation process. Our team will
        review the accreditation evidence provided within 1-2 business days. You
        can track the status of your accreditation in your{' '}
        <Link className="underline" to="/settings/accreditation">
          settings
        </Link>{' '}
        page.
      </>
    );
    currentStep = 5;
  }

  return (
    <Card data-testid="accreditationCard" className="border-top-card">
      <ProgressBarBorder steps={4} currentStep={currentStep} />
      <div
        className={`${styles.textContent} ${
          isPending ? styles.accreditationPending : ''
        }`}
      >
        <h4 className="margin-top-0">{heading}</h4>
        <p>{copy}</p>
        {!isPending && (
          <Button
            className="float-right"
            data-testid="verifyButton"
            wrapper={
              <Link to={`/verify_accreditation/${accountReferenceId}`} />
            }
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default AccreditationCard;
