import {
  Button,
  Card,
  EMLoadingIcon,
  Input,
  RadioButton
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadCompletedAccounts } from 'redux/actions/account';
import { LoadCompletedAccountsResponse } from 'types/actions/account';
import {
  CancelInvestmentPayload,
  LoadInvestmentResponse
} from 'types/actions/investments';
import { Closing } from 'types/api/closing';
import { Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import {
  cancelInvestment,
  loadInvestment
} from '../../../redux/actions/investments';
import { loadClosing } from '../../../redux/actions/offerings';
import EmAnalytics from '../../../utilities/em_analytics';
import { handleErrorResponse } from '../../../utilities/errorHandlers';
import humane from '../../../utilities/humane';
import { throwReactHookFormSubmissionErrors } from '../../../utilities/validation';
import InvestmentStatus from '../components/InvestmentStatus/InvestmentStatus';
import InvestmentTitle from '../components/InvestmentTitle/InvestmentTitle';
import { cancelInvestmentSchema } from '../validations';

const callIrLabel =
  "I don't understand this offering / This offering is confusing to me.";

const getCancelDetailLabel = (cancelReason: string) => {
  switch (cancelReason) {
    case "I don't have the capital to fund this investment on time.":
      return 'Is there anything we can do?';
    case "I don't like this offering.":
      return "Tell us why you don't like this offering";
    case 'Other':
      return 'Please Describe';
    case callIrLabel:
      return 'Call IR';
  }
  return '';
};

type Params = {
  closing_id: string;
  investment_id: string;
  offering_id: string;
};

interface Props {
  accounts: LoadCompletedAccountsResponse;
  closing: Closing;
  dispatch: Dispatch;
  investment: LoadInvestmentResponse;
  offering: Offering;
}

type FormFields = CancelInvestmentPayload;

const CancelInvestment = ({
  accounts,
  dispatch,
  offering,
  closing,
  investment
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(cancelInvestmentSchema)
  });

  const cancelReason = watch('cancel_reason');

  useEffect(() => {
    const newInvestmentRoute = `/invest/${params.closing_id}/investment/new`;

    if (!params.closing_id) {
      navigate('/');
    } else if (!params.investment_id) {
      navigate(newInvestmentRoute);
    } else {
      dispatch(loadClosing(params.closing_id)).catch(error =>
        handleErrorResponse(navigate, error)
      );
      dispatch(loadInvestment(params.investment_id)).catch(error =>
        handleErrorResponse(navigate, error)
      );
      dispatch(loadCompletedAccounts()).catch(error =>
        handleErrorResponse(navigate, error)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    return dispatch(cancelInvestment(investment.id, values))
      .then(() => {
        EmAnalytics.track('Investment Cancelled', 'Investment', {
          offering: offering.title,
          closing: closing.name,
          amount: investment.amount,
          first_time_investment: investment.first_time_investment,
          investment_id: investment.id.toString(),
          ...values
        });
        humane.notice('You have successfully cancelled your investment');
        navigate(`/invest/${offering.id}/closings/${closing.id}/`);
      })
      .catch(error => throwReactHookFormSubmissionErrors(error, setError));
  };

  const clearReason = () => {
    setValue('cancel_detail', '');
  };

  const isLoading = !offering || !accounts || !investment || !closing;

  return isLoading ? (
    <EMLoadingIcon />
  ) : (
    <Container className="container-narrow">
      <InvestmentTitle title={offering.title} stage={closing.stage} />
      <InvestmentStatus
        step={1}
        investment={investment}
        closing={closing}
        cancelPage
      />
      <Card>
        <h4 className="margin-top-0">Cancel My Investment</h4>
        <form
          id="reason_form"
          name="reason_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="margin-xx">
            <h6>Reasons for the cancellation:</h6>
            <div className="margin-xx">
              <Controller
                name="cancel_reason"
                control={control}
                render={({ field }) => (
                  <>
                    <RadioButton
                      {...setRadioFieldProps(field, errors, callIrLabel)}
                      onChange={e => {
                        e.target.value = callIrLabel;
                        field.onChange(e);
                        clearReason();
                      }}
                      label={callIrLabel}
                      hideError
                    />
                    <RadioButton
                      {...setRadioFieldProps(
                        field,
                        errors,
                        "I don't have the capital to fund this investment on time."
                      )}
                      onChange={e => {
                        e.target.value =
                          "I don't have the capital to fund this investment on time.";
                        field.onChange(e);
                        clearReason();
                      }}
                      label="I don't have the capital to fund this investment on time."
                      hideError
                    />
                    <RadioButton
                      {...setRadioFieldProps(
                        field,
                        errors,
                        "I don't like this offering."
                      )}
                      onChange={e => {
                        e.target.value = "I don't like this offering.";
                        field.onChange(e);
                        clearReason();
                      }}
                      label="I don't like this offering."
                      hideError
                    />
                    <RadioButton
                      {...setRadioFieldProps(field, errors, 'Other')}
                      onChange={e => {
                        e.target.value = 'Other';
                        field.onChange(e);
                        clearReason();
                      }}
                      label="Other"
                    />
                  </>
                )}
              />
            </div>
            {cancelReason && cancelReason !== callIrLabel && (
              <Controller
                name="cancel_detail"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label={getCancelDetailLabel(cancelReason)}
                  />
                )}
              />
            )}
          </div>

          {cancelReason === callIrLabel && (
            <p>
              We can help get you answers! Give us a ring and we&apos;d be happy
              to walk you through this offering.
              <br /> Our number is 646-844-9918
            </p>
          )}

          <FormError errors={errors} />

          <div className="forwardBackButtonWrap">
            {cancelReason === callIrLabel ? (
              <>
                <Button
                  href="tel:+16468449918"
                  style={{
                    width: 180
                  }}
                >
                  Call IR
                </Button>
              </>
            ) : (
              <Button type="submit" loading={isSubmitting}>
                Cancel Investment
              </Button>
            )}
            <button
              type="button"
              className="text-link"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </form>
      </Card>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    accounts: state.account.completedAccounts,
    investment: state.investments.investment,
    offering: state.offerings.offering,
    closing: state.offerings.closing
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(CancelInvestment);
