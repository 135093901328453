import { Input, RadioButton } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { SignatureStyle } from 'types/actions/investments';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import utils from '../../../../../../utilities/utils';
import { SignFormValues } from '../../types';
import * as styles from './Signature.module.scss';

interface Props<T extends FieldValues> {
  completedName?: string;
  control?: Control<T>;
  errors?: FieldErrors<T>;
  formValues?: SignFormValues;
  signatureStyle?: SignatureStyle;
}

const Signature = <T extends FieldValues>({
  completedName,
  formValues,
  signatureStyle,
  control,
  errors
}: Props<T>) => {
  let selectedSignatureStyle = 'Kalam';
  if (signatureStyle) selectedSignatureStyle = signatureStyle;
  else if (formValues?.user_name)
    selectedSignatureStyle = formValues.signature_style;

  const renderedSignatureClassName =
    styles[utils.camelCase(selectedSignatureStyle)];
  const signatureName = formValues?.user_name || completedName || '-';

  return (
    <div>
      {!completedName && (
        <Row>
          <Col md={5}>
            <Controller
              name={'user_name' as Path<T>}
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="Signature" />
              )}
            />
          </Col>
          <Col md={7}>
            <div className={styles.radioButtons}>
              <Controller
                name={'signature_style' as Path<T>}
                control={control}
                render={({ field }) => (
                  <>
                    <div className={`${styles.radioWrap} ${styles.kalam}`}>
                      <RadioButton
                        {...setRadioFieldProps(field, errors, 'Kalam')}
                        label="Basic"
                      />
                    </div>
                    <div
                      className={`${styles.radioWrap} ${styles.theNautigal}`}
                    >
                      <RadioButton
                        {...setRadioFieldProps(field, errors, 'The Nautigal')}
                        label="Classic"
                      />
                    </div>
                    <div className={`${styles.radioWrap} ${styles.jomolhari}`}>
                      <RadioButton
                        {...setRadioFieldProps(field, errors, 'Jomolhari')}
                        label="Modern"
                      />
                    </div>
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      )}

      <div
        className={`${styles.renderedSignature} ${renderedSignatureClassName}`}
        data-testid="renderedSignature"
      >
        {signatureName}
      </div>
    </div>
  );
};

export default Signature;
