import { combineReducers } from 'redux';

import account from './account';
import auth from './auth';
import bankAccount from './bank-account';
import capitalCall from './capital-call';
import documents from './documents';
import invest from './invest';
import investments from './investments';
import invite from './invite';
import modals from './modals';
import offeringTaxStatuses from './offering-tax-statuses';
import offerings from './offerings';
import payments from './payments';
import portfolio from './portfolio';
import posts from './posts';
import userSettings from './user-settings';
import userUpdateAlerts from './userUpdateAlerts';

export default combineReducers({
  offerings,
  posts,
  auth,
  invest,
  documents,
  invite,
  modals,
  portfolio,
  userSettings,
  userUpdateAlerts,
  investments,
  bankAccount,
  account,
  payments,
  capitalCall,
  offeringTaxStatuses
});
