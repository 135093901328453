import { Button, Checkbox, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { logout } from 'redux/actions/auth';
import {
  getInviteeData,
  invitationInit,
  submitInviteSignupForm,
  submitSignupForm,
  updateSignupValues
} from 'redux/actions/onboarding';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import { setCheckboxFieldProps, setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { SignupStep, SignupValues } from '../types';
import { startSchema } from '../validation';

type Params = {
  referral_token: string;
};

interface Props {
  defaultValues: SignupValues;
  dispatch: Dispatch;
  invitationData?: {
    email: string;
    invited_by: string;
    signup_link_name: string;
  };
  invitee?: {
    name: string;
  };
  user: User;
}

interface FormFields {
  accepted_tos_and_pp: boolean;
  email: string;
}

const SignUp = ({
  dispatch,
  defaultValues,
  invitee,
  invitationData,
  user
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<Params>();
  const invitedByEmail = !!invitationData?.invited_by;
  const referralToken = params?.referral_token;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue
  } = useForm<FormFields>({
    resolver: yupResolver(startSchema),
    values: defaultValues as FormFields
  });

  useRecaptcha();

  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';
    const query = queryString.parse(location?.search);
    if (referralToken) {
      dispatch(getInviteeData(referralToken)).catch(err => {
        humane.error(err.body.message);
        navigate('/users/signup/start');
      });
    }

    if (query) {
      if (
        query.invitation_token &&
        location.pathname === '/users/invitation/accept'
      )
        dispatch(invitationInit(query.invitation_token)).catch(err => {
          humane.error(err.body.message);
          navigate('/users/signup/start');
        });

      if (query.email) {
        setValue('email', query.email as string);
      }

      const utmParams = [
        'utm_campaign',
        'utm_source',
        'utm_medium',
        'utm_content',
        'utm_term',
        'utm_invite'
      ];

      utmParams.forEach(utmParam => {
        if (query[utmParam]) utils.setCookie(utmParam, query[utmParam]);
      });
    }
  }, [dispatch, location, navigate, referralToken, setValue]);

  useEffect(() => {
    if (user && referralToken) {
      dispatch(logout());
    }
  }, [dispatch, user, referralToken]);

  const onSubmit = async values => {
    const query = queryString.parse(location.search);

    const irClickId = utils.getCookie('irclickid');
    if (irClickId) values.irclickid = irClickId;

    if (query.invitation_token)
      values.invitation_token = query.invitation_token;

    if (referralToken) values.signup_link_name = referralToken;

    const token = await getRecaptchaToken('signup');

    const submitValues = {
      step: 1 as SignupStep,
      user: {
        ...values,
        captcha_response: token
      }
    };

    const inviteSubmitValues = {
      user: {
        accepted_tos_and_pp: values.accepted_tos_and_pp,
        irclickid: values.irclickid,
        invitation_token: values.invitation_token
      }
    };

    return dispatch(
      invitedByEmail
        ? submitInviteSignupForm(inviteSubmitValues)
        : submitSignupForm(submitValues)
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        navigate('/users/signup/password');
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  let heading = 'Start your free account in seconds.';
  let inviteeName = null;

  const isInvite = invitee || invitationData?.invited_by;

  if (isInvite) {
    if (invitedByEmail) {
      inviteeName = invitationData.invited_by;
    } else {
      inviteeName = invitee.name;
    }

    heading = `Sign Up, and earn a better return on your first investment
    courtesy of
    ${inviteeName}`;
  }

  return (
    <SignUpWrap
      introBackgroundColor="gray"
      introContent={
        <>
          <h1>
            Welcome to Modern Real Estate Investing.
            <sup className="size-12">TM</sup>
          </h1>
          <p>
            You're moments away from elevating your portfolio to new heights.
          </p>
        </>
      }
    >
      <h3>{heading}</h3>

      {isInvite ? (
        <>
          <p>
            Join {inviteeName} on EQUITYMULTIPLE, and build a diversified
            portfolio of pre-vetted, institutional-grade commercial real estate.
          </p>

          <p className="margin-xx">
            Because you are accepting their invite, we will give you a credit.
            On your first investment, this credit is automatically applied and
            you'll earn a yield bump. Invite others, and when they invest, you
            earn additional credits.
          </p>
        </>
      ) : (
        <p className="margin-xx">
          This email is how we'll communicate details about your account,
          announce new investment opportunities, share new investor resources,
          and more.
        </p>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...setFieldProps(field, errors)}
              label="Email Address"
              className="margin-xx"
              disabled={invitedByEmail}
            />
          )}
        />

        <Controller
          name="accepted_tos_and_pp"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label={
                <span>
                  I agree to the latest{' '}
                  <a
                    href="https://www.equitymultiple.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.equitymultiple.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Privacy Policy
                  </a>
                  , and I understand that the platform may contain information
                  regarding hypothetical investment returns (including target
                  returns) and that such performance should be viewed as
                  hypothetical and not as an indication of future performance.
                  There can be no assurance that such returns will be realized.
                  I represent and acknowledge that I have (1) access to the
                  resources to independently analyze the information that may be
                  provided and (2) the financial expertise to understand that
                  any hypothetical performance results provided have many
                  inherent risks and limitations. Further, I understand that
                  additional information with respect to such risks and
                  limitations is available from EM Advisor, LLC upon request.
                </span>
              }
              data-testid="acceptCheckbox"
              className="margin-xxx"
              topAligned
            />
          )}
        />

        <FormError errors={errors} />

        <Button
          type="submit"
          loading={isSubmitting}
          variant="orange"
          className="button-fixed-width"
        >
          Continue
        </Button>
      </form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const defaultValues = store.auth.signupValues;

  // The email isn't submitted (it's deleted in onSubmit), just adding it to prefill the field and make validation easier
  if (store.auth.invitationData?.invited_by) {
    defaultValues.email = store.auth.invitationData.email;
  }

  return {
    defaultValues,
    invitee: store.auth.invitee,
    invitationData: store.auth.invitationData,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(SignUp);
