import {
  Button,
  Card,
  EMLoadingIcon,
  ErrorLabel,
  Input,
  RadioButton,
  Select
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { loadCompletedAccounts } from 'redux/actions/account';
import { LoadCompletedAccountsResponse } from 'types/actions/account';
import { User } from 'types/actions/auth';
import { Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import RichText from '../../components/RichText';
import {
  agreeConfidentialityAgreement,
  confidentialityAgreementAccepted,
  loadOffering
} from '../../redux/actions/offerings';
import humane from '../../utilities/humane';
import utils from '../../utilities/utils';
import { throwReactHookFormSubmissionErrors } from '../../utilities/validation';
import { netWorths, portfolioValues } from '../Accounts/contents';
import * as styles from './ConfidentialityAgreement.module.scss';
import { confidentialityAgreementSchema } from './validation';

type Params = {
  closing_id: string;
  offering_id: string;
};

type Props = {
  accounts: LoadCompletedAccountsResponse;
  dispatch: Dispatch;
  offering: Offering;
  user: User;
};

interface FormFields {
  scrolledToBottom: boolean;
  user: {
    fullName: string;
    net_worth: string;
    portfolio_value: string;
    qualified_purchaser: boolean;
    signature: string;
  };
}

const ConfidentialityAgreement = ({
  offering,
  accounts,
  dispatch,
  user
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const fullName = user?.first_name.trim() + ' ' + user.last_name.trim();

  const accountType = accounts[0]?.type;

  const defaultValues = {
    user: {
      fullName
    },
    scrolledToBottom: false
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(confidentialityAgreementSchema),
    defaultValues
  });

  const scrolledToBottom = watch('scrolledToBottom');

  const setAgreementRead = utils.debounce(() => {
    const section = document.getElementById('confidentiality-agreement-block');
    if (section) {
      const scrollHeight =
        section.scrollHeight - section.offsetHeight - section.scrollTop;
      if (scrollHeight < 150) {
        setValue('scrolledToBottom', true);
        // Both functions use each other so there's no way around this error
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        removeScrollListener();
      }
    }
  }, 100);

  const removeScrollListener = () => {
    const section = document.getElementById('confidentiality-agreement-block');
    if (section) {
      section.removeEventListener('scroll', setAgreementRead);
    }
  };

  useEffect(() => {
    dispatch(confidentialityAgreementAccepted(params.offering_id)).then(
      (accepted: boolean) => {
        if (accepted) {
          navigate(
            `/invest/${params.offering_id}/closings/${params.closing_id}`
          );
        }
      }
    );
    dispatch(loadCompletedAccounts());
    dispatch(loadOffering(params.offering_id)).then(() => {
      const section = document.getElementById(
        'confidentiality-agreement-block'
      );
      if (section) {
        section.addEventListener('scroll', setAgreementRead);
      }
    });

    return () => {
      removeScrollListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.offering_id, params.closing_id]);

  const handleFormSubmit = values => {
    return dispatch(agreeConfidentialityAgreement(offering.id, values))
      .then(res => {
        if (res) {
          if (res.confidentiality_accepted) {
            navigate(`/invest/${offering.id}/closings/${params.closing_id}`);
          } else {
            humane.error(
              `You must be a Qualified Purchaser with more than $5M Net Worth to view ${offering.title}`
            );
            navigate('/');
          }
        }
      })
      .catch(err => throwReactHookFormSubmissionErrors(err, setError));
  };

  return !offering ? (
    <EMLoadingIcon />
  ) : (
    <div>
      <Container className="container-narrow">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Card className={styles.card}>
            <h4>
              To proceed, please review and complete the information below
            </h4>
            <p>
              In order to view <strong>{offering.title}</strong> we ask that you
              confirm your status as a Qualified Purchaser and sign a
              confidentiality agreement.
            </p>

            <h6>1. Are you a Qualified Purchaser?</h6>
            <p>
              If you plan to invest personally or on behalf of a trust or
              family-owned entity, do you or the investment vehicle own at least
              $5 million in invested assets?
            </p>
            <Row />
            <Col md={6} className="overflow-visible">
              <Controller
                name="user.qualified_purchaser"
                control={control}
                render={({ field }) => (
                  <>
                    <RadioButton
                      {...setRadioFieldProps(field, errors, true)}
                      label="Yes"
                      hideError
                    />
                    <RadioButton
                      {...setRadioFieldProps(field, errors, false)}
                      label="No"
                    />
                  </>
                )}
              />
            </Col>

            <h6>2. Update your net worth</h6>
            <p>Please select your net worth</p>
            <Row>
              <Col md={6} className="overflow-visible">
                {accountType === 'entity' ? (
                  <Controller
                    name="user.portfolio_value"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...setFieldProps(field, errors)}
                        label="Portfolio Value"
                        options={portfolioValues.map(key => ({
                          label: key,
                          value: key
                        }))}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="user.net_worth"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...setFieldProps(field, errors)}
                        label="Net Worth"
                        options={netWorths.map(key => ({
                          label: key,
                          value: key
                        }))}
                      />
                    )}
                  />
                )}
              </Col>
            </Row>

            <h6>3. Confidentiality Agreement</h6>
            <div id="confidentiality-agreement-block">
              <RichText text={offering.confidentiality_status} sanitize />
              <div className={styles.signatureBox}>
                <p>
                  IT IS IMPORTANT THAT YOU READ CAREFULLY AND UNDERSTAND THIS
                  AGREEMENT. BY TYPING YOUR FULL NAME AND CLICKING THE “SUBMIT”
                  BUTTON LOCATED ON THIS PAGE, YOU AND ANY LEGAL ENTITY YOU
                  REPRESENT WILL BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE
                  WITH ALL OF THE TERMS OF THIS AGREEMENT AND DO NOT AGREE TO BE
                  BOUND BY THIS AGREEMENT, PLEASE DO NOT CLICK THE “SUBMIT”
                  BUTTON. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU MAY NOT USE
                  OR ACCESS THE PROPRIETARY INFORMATION.
                </p>
                <Row>
                  <Col md={6}>
                    <h5>{fullName}</h5>
                    <Controller
                      name="user.signature"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...setFieldProps(field, errors)}
                          label="Full Name"
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            {!scrolledToBottom && (
              <ErrorLabel message="You must read the entire confidentiality agreement. Please scroll to the bottom of the agreement." />
            )}

            <FormError errors={errors} />

            <div>
              <Button
                type="submit"
                className={styles.submitButton}
                loading={isSubmitting}
              >
                Submit
              </Button>
              <Link className={styles.backLink} to="/">
                Return to Browse Offerings
              </Link>
            </div>
          </Card>
        </form>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    offering: state.offerings.offeringData,
    accounts: state.account.completedAccounts,
    user: state.auth.user
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(ConfidentialityAgreement);
