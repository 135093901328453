import { AnyAction } from 'redux';

import * as actions from '../actions/offerings';
const initialState = {
  closing: {},
  currentOfferingId: null,
  fetching: false,
  investment: {},
  offering: {},
  offerings: [],
  user: {},
  loadingOffering: true,
  loadedOffering: false,
  distributionReinvestment: null,
  submitting: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_ALL:
    case actions.LOAD_OFFERING_INVESTMENTS:
    case actions.LOAD_DISTRIBUTION_REINVESTMENT:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case actions.UPDATE_DISTRIBUTION_REINVESTMENT:
      return {
        ...state,
        submitting: true
      };

    case actions.LOAD_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        closings: action.result.closings,
        offerings: action.result.offerings
      };

    case actions.LOAD_DISTRIBUTION_REINVESTMENT_SUCCESS:
      return {
        ...state,
        distributionReinvestment: action.result
      };

    case actions.LOAD_ALL_FAIL:
    case actions.LOAD_OFFERING_INVESTMENTS_FAIL:
    case actions.LOAD_DISTRIBUTION_REINVESTMENT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: action.error
      };

    case actions.FETCH_OFFERING:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case actions.FETCH_OFFERING_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        offering: action.result.offering,
        closing: action.result.closing,
        user: action.result.user
      };

    case actions.FETCH_OFFERING_FAIL:
      return {
        ...state,

        fetched: false,
        fetching: false,
        loadOfferingWithComponentsError: action.error
      };

    case actions.LOAD_OFFERING:
      return {
        ...state,
        loadingOffering: true,
        loadedOffering: false
      };

    case actions.LOAD_OFFERING_SUCCESS:
      return {
        ...state,
        loadingOffering: false,
        loadedOffering: true,
        offeringData: action.result
      };

    case actions.LOAD_OFFERING_INVESTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };

    case actions.LOAD_OFFERING_FAIL:
      return {
        ...state,
        loadingOffering: false,
        loadedOffering: false,
        loadErrors: action.error
      };

    case actions.LOAD_CLOSING_SUCCESS:
      return {
        ...state,
        loadingClosing: false,
        loadedClosing: true,
        ...action.result
      };

    case actions.LOAD_CLOSING:
      return {
        ...state,
        loadingClosing: true,
        loadedClosing: false
      };

    case actions.LOAD_CLOSING_FAIL:
      return {
        ...state,
        loadingClosing: false,
        loadedClosing: false,
        closingData: action.error
      };

    case actions.SUBMIT_AGREEMENT:
      return {
        ...state,
        submittingAgreement: true
      };

    case actions.SUBMIT_AGREEMENT_SUCCESS:
    case actions.SUBMIT_AGREEMENT_FAIL:
      return {
        ...state,
        submittingAgreement: false
      };

    case actions.UPDATE_DISTRIBUTION_REINVESTMENT_SUCCESS:
    case actions.UPDATE_DISTRIBUTION_REINVESTMENT_FAIL:
      return {
        ...state,
        submitting: false
      };

    default:
      return state;
  }
};
