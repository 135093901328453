import OfferingSignUp from 'components/OfferingSignUp/OfferingSignUp';
import { setBackgroundColor } from 'containers/SignUp/helpers';
import { IntroBackgroundColor, SignupStep } from 'containers/SignUp/types';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { User } from 'types/actions/auth';

import IntroColumn from '../IntroColumn/IntroColumn';
import * as styles from './SignUpWrap.module.scss';

interface Props {
  children: React.ReactNode;
  introBackgroundColor: IntroBackgroundColor;
  introContent: React.ReactNode;
  step?: SignupStep;
  user?: User;
}

const getBackLink = (step: SignupStep) => {
  let stepRoute = '';

  switch (step) {
    case 2:
      stepRoute = 'start';
      break;
    case 3:
      stepRoute = 'password';
      break;
    case 4:
      stepRoute = 'name';
  }

  return `/users/signup/${stepRoute}`;
};

const SignUpWrap = ({
  children,
  introBackgroundColor,
  introContent,
  step,
  user
}: Props) => {
  useEffect(() => {
    setBackgroundColor();
  }, []);

  const hideTopLinks = (!!user && step === 3) || step === 5;
  const hideSignInLink = !!user;

  return (
    <div className="noHeaderWrap">
      <Container className="container-wide">
        <OfferingSignUp />
        <Row className={styles.row}>
          <Col lg={6}>
            <IntroColumn backgroundColor={introBackgroundColor}>
              {introContent}
            </IntroColumn>
          </Col>
          <Col lg={6} className={styles.content}>
            <div className={styles.topLinks}>
              {!hideTopLinks && (
                <>
                  {step > 1 && (
                    <Link
                      to={getBackLink(step)}
                      className={`underline ${styles.backLink}`}
                      data-testid="backLink"
                    >
                      Back
                    </Link>
                  )}

                  {!hideSignInLink && (
                    <div>
                      Already have an account?{' '}
                      <Link to="/users/sign_in" className="underline">
                        Sign In
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.mainContent}>{children}</div>
            <div className={styles.bottomLinks}>
              <a
                className="underline"
                href="https://equitymultiple.com/terms"
                target="_blank"
                rel="noreferrer"
                data-testid="termsOfServiceLink"
              >
                Terms of Service
              </a>
              <a
                className="underline"
                href="https://equitymultiple.com/privacy"
                target="_blank"
                rel="noreferrer"
                data-testid="privacyPolicyLink"
              >
                Privacy Policy
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUpWrap;
