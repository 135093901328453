import { Button } from '@equitymultiple/react-eui';
import laptop from 'images/laptop.svg?url';
import smartphone from 'images/smartphone.svg?url';
import tablet from 'images/tablet.svg?url';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { removeDevice, userDevices } from 'redux/actions/auth';
import { UserDevicesResponse } from 'types/actions/auth';
import { Dispatch } from 'types/redux';

import * as styles from './UserDevice.module.scss';

const deviceImages = {
  tablet,
  laptop,
  smartphone
};

type Props = {
  devices: UserDevicesResponse;
  dispatch: Dispatch;
};

const UserDevice = ({ dispatch, devices }: Props) => {
  useEffect(() => {
    dispatch(userDevices());
  }, [dispatch]);

  const handleResetLink = (id: string) => {
    if (window.innerWidth > 767) {
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const removeUserDevice = deviceId => {
    dispatch(removeDevice(deviceId));
  };

  const listUserDevices = () =>
    devices.map(device => (
      <tr key={device.id} className={device.current ? styles.current : ''}>
        <td className={styles.indicator} />
        <td className={styles.deviceIcon}>
          <img alt={device.type} src={deviceImages[device.type]} />
        </td>
        <td>
          <h4>{device.device_name || device.os_name}</h4>
          <div>
            {device.address || 'Unrecognized'}
            <span className={styles.dash}> - </span>
            {device.current ? (
              <span className={`${styles.lastSeen} ${styles.currentDevice}`}>
                This Device
              </span>
            ) : (
              <span className={styles.lastSeen}>
                {moment(new Date()).diff(device.last_seen_at, 'days') === 0
                  ? moment(device.last_seen_at).fromNow()
                  : moment(device.last_seen_at).format('MMM D, h:mma')}
              </span>
            )}
          </div>
          <div className={styles.mobileDisplay}>
            <div>
              <div className={styles.tableHeader}>Browser</div>
              {device.browser_name} {device.browser_version}
            </div>
            {!device.current && (
              <div className={styles.removeDevice}>
                <Button
                  type="button"
                  variant="orange"
                  onClick={() => removeUserDevice(device.id)}
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        </td>
        <td className={styles.largeDisplay}>
          {!device.current && (
            <span className={styles.removeDevice}>
              <Button
                type="button"
                className="float-right"
                variant="outlined"
                onClick={() => removeUserDevice(device.id)}
              >
                Remove
              </Button>
            </span>
          )}
          {device.browser_name} {device.browser_version}
        </td>
      </tr>
    ));

  return (
    <div>
      <p>
        Devices that are currently signed in or have been active in the last 180
        days.
      </p>
      <table className={styles.devicesTable}>
        <thead>
          <tr>
            <th className={styles.indicator} aria-label="indicator" />
            <th aria-label="blank" />
            <th>Device</th>
            <th className={styles.largeDisplay}>Browser</th>
          </tr>
        </thead>
        <tbody>
          {devices && listUserDevices()}
          <tr key="reset-password">
            <td className={`${styles.largeDisplay} ${styles.indicator}`} />
            <td colSpan={3} className="text-center">
              Don&apos;t recognize a device? We recommend you{' '}
              <button
                onClick={() => handleResetLink('user-settings')}
                className="text-link"
                type="button"
              >
                change your password
              </button>{' '}
              to secure your account.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    devices: state.auth.devices
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(UserDevice);
