import { Button, Card, RadioButton } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { distributionReinvestmentElectionsSchema } from 'containers/Investment/validations';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadDistributionReinvestmentInOffering,
  updateDistributionReinvestmentInOffering
} from 'redux/actions/offerings';
import { SignatureStyle } from 'types/actions/investments';
import { DistributionReinvestment } from 'types/api/offering';
import { Dispatch } from 'types/redux';
import { setRadioFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { AcknowledgeAndSign } from '../Sign/components/AcknowledgeAndSign/AcknowledgeAndSign';
import * as styles from './DistributionReinvestmentElections.module.scss';

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h3>
      <Skeleton width="60%" />
    </h3>
    <Row>
      <Col md={4}>
        <p>
          <Skeleton width="60%" />
        </p>
      </Col>
      <Col md={4}>
        <p>
          <Skeleton width="90%" />
        </p>
      </Col>
      <Col md={4}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
    </Row>
    <h5 className="margin-top-xx margin-xxx">
      <Skeleton width="50%" />
    </h5>
    <p className="margin-xxx">
      <Skeleton width="90%" />
    </p>
    <p className="margin-xxx">
      <Skeleton width="90%" />
    </p>
  </div>
);

interface SubmitValues {
  datetime?: string;
  investment_id: number;
  opt_in: boolean;
  signature_style?: SignatureStyle;
}

type Params = {
  offering_id: string;
};

interface FormFields {
  documents?: Record<string, boolean>;
  opt_in?: boolean;
  pledge1?: boolean;
  pledge2?: boolean;
  pledge3?: boolean;
  registeredName?: string;
  signature_style: SignatureStyle;
}

type Props = {
  dispatch: Dispatch;
  distributionReinvestment: DistributionReinvestment;
  loading: boolean;
  registeredName: string;
};

const DistributionReinvestmentElections = ({
  dispatch,
  distributionReinvestment,
  registeredName,
  loading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const investmentAmount = parseFloat(distributionReinvestment?.amount);

  const defaultValues = {
    registeredName,
    signature_style: 'Kalam' as SignatureStyle
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitted },
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(distributionReinvestmentElectionsSchema),
    values: defaultValues
  });

  useEffect(() => {
    document.title = 'Investment Elections | EquityMultiple';

    dispatch(loadDistributionReinvestmentInOffering(params.offering_id)).catch(
      () => {
        navigate('/', { replace: true });
      }
    );
  }, [dispatch, params.offering_id, navigate]);

  const onSubmit = values => {
    const submitValues: SubmitValues = {
      investment_id: distributionReinvestment.id,
      opt_in: utils.normalizeBoolean(values.opt_in)
    };

    if (submitValues.opt_in) {
      submitValues.datetime = new Date().toISOString();
      submitValues.signature_style = values.signature_style;
    }

    return dispatch(
      updateDistributionReinvestmentInOffering(params.offering_id, submitValues)
    )
      .then(() => {
        if (submitValues.opt_in) {
          navigate(
            `/invest/${distributionReinvestment.destination_closing.id}/investment/${distributionReinvestment.id}/confirm`
          );
        } else {
          navigate(
            `/invest/${distributionReinvestment.destination_offering.id}/closings/${distributionReinvestment.destination_closing.id}`
          );
        }
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  const setDownloadedField = (documentIndex: number) => {
    setValue(`documents.doc${documentIndex}`, true);
  };

  const documents = watch('documents');
  const optIn = watch('opt_in');

  const documentsNotDownloaded =
    documents && Object.keys(documents).some(document => !documents[document]);

  const madeSelection = optIn !== undefined;
  const optingIn = optIn === true;

  return (
    <Container className="container-narrow">
      {loading ? (
        <Card>
          <LoadingSkeleton />
        </Card>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <h3 className="margin-top-0">
              Reinvest your upcoming REM Fund Distribution into the Ascent Fund
            </h3>
            <div data-testid="topDetails">
              <Row>
                <Col md={4}>
                  <div className="text-label">Source Investment</div>
                  <p>{distributionReinvestment?.source_offering.title}</p>
                </Col>
                <Col md={4}>
                  <div className="text-label">Original Investment Amount</div>
                  <p>{utils.formatCurrency(investmentAmount)}</p>
                </Col>
                <Col md={4}>
                  <div className="text-label">Destination Offering</div>
                  <p>{distributionReinvestment?.destination_offering.title}</p>
                </Col>
              </Row>
            </div>

            <h3>Reinvestment Options</h3>
            <Controller
              name="opt_in"
              control={control}
              render={({ field }) => (
                <>
                  <RadioButton
                    {...setRadioFieldProps(field, errors, true)}
                    label="Reinvest Capital Balance"
                    hideError
                  />
                  <RadioButton
                    {...setRadioFieldProps(field, errors, false)}
                    label="Opt Out"
                  />
                </>
              )}
            />
          </Card>
          {optingIn && (
            <Card data-testid="sign">
              <h3 className="margin-top-0">Sign</h3>
              <p className="margin-xx">
                Complete this step to secure your spot in the investment.
              </p>
              <AcknowledgeAndSign<FormFields>
                isSubmitted={isSubmitted}
                setDownloadedField={setDownloadedField}
                formValues={watch()}
                offeringId={Number(params.offering_id)}
                signRequiredDocuments={
                  distributionReinvestment?.destination_offering
                    .sign_required_documents
                }
                documentsNotDownloaded={documentsNotDownloaded}
                control={control}
                errors={errors}
              />
            </Card>
          )}

          {madeSelection && (
            <Card data-testid="summary">
              <h3 className="margin-top-0">Summary</h3>

              <p className="margin-xx">
                {optingIn
                  ? 'You are going to reinvest your principal balance to the next destination offering, and withdraw accrued interest.'
                  : 'You are going to opt-out of reinvesting.'}
              </p>

              <div data-testid="summaryDetails">
                {optingIn ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <div className="text-label">Rollover Total</div>
                        <p>{utils.formatCurrency(investmentAmount)}</p>
                      </Col>
                      <Col md={6}>
                        <div className="text-label">Balance Rollover</div>
                        <p>{utils.formatCurrency(investmentAmount)}</p>
                      </Col>
                    </Row>
                    <hr className={styles.rowSeparator} />
                    <Row>
                      <Col md={6}>
                        <div className="text-label">Interest Withdrawal</div>
                        <p className="italic">
                          To be updated upon buyout close
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col sm={6}>
                      <div className="text-label">Balance Withdrawal</div>
                      <p>{utils.formatCurrency(investmentAmount)}</p>
                    </Col>
                    <Col sm={6}>
                      <div className="text-label">Interest Withdrawal</div>
                      <p className="italic">To be updated upon buyout close</p>
                    </Col>
                  </Row>
                )}
              </div>

              <FormError errors={errors} />

              <div className={styles.buttonWrap}>
                <p className="italic">
                  *Withdrawal amounts will not be reinvested into the Ascent
                  Fund
                </p>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  data-testid="submitButton"
                >
                  {optingIn ? 'Sign and Save' : 'Save'}
                </Button>
              </div>
            </Card>
          )}
        </form>
      )}
    </Container>
  );
};

function mapStateToProps(store) {
  const userRegisteredName = `${store.auth.user.first_name} ${store.auth.user.last_name}`;

  return {
    distributionReinvestment: store.offerings.distributionReinvestment,
    loading: store.offerings.loadingReinvestment,
    registeredName: userRegisteredName
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(DistributionReinvestmentElections);
