import { Button, Card, EMLoadingIcon, Table } from '@equitymultiple/react-eui';
import jpg from 'images/icons/jpg.svg?url';
import pdf from 'images/icons/pdf.svg?url';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadDocuments } from 'redux/actions/documents';
import { loadTaxOfferingStatuses } from 'redux/actions/offering-tax-statuses';
import { User } from 'types/actions/auth';
import { LoadDocumentsResponse } from 'types/actions/documents';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { canAccessPage } from 'utilities/user';
import utils from 'utilities/utils';

import * as styles from './MyDocuments.module.scss';
import DownloadPopUp from './TaxDocuments/DownloadPopUp';
import TaxDocuments from './TaxDocuments/TaxDocuments';

const docTitles = {
  tax_documents: 'Tax Documents',
  investment_documents: 'Investment Documents',
  account_documents: 'Account Documents'
};

const colNames = ['', ' ', 'Documents', 'Type', 'Year'];

const sendAccountStatementDownloadEvent = () => {
  EmAnalytics.track('Investor Downloaded Account Statement', '', {
    page: 'My Documents'
  });
};

const addCountToDuplicateDocs = documents => {
  const groupedDocs = {};
  // Create an object containing arrays of docs with the same offering ID and tax year
  documents.forEach(document => {
    const doc = { ...document };
    const key = doc.offering_id + '_' + doc.year;
    if (groupedDocs[key]) groupedDocs[key].push(doc);
    else groupedDocs[key] = [doc];
  });

  // Iterate through the arrays and append a count to the offering title if needed, then merge arrays
  const updatedDocs = Object.keys(groupedDocs)
    .map(docGroup => {
      const docsInGroup = groupedDocs[docGroup];
      if (docsInGroup.length > 1) {
        const total = docsInGroup.length;
        docsInGroup.reverse().forEach((doc, index) => {
          doc.title = `${doc.title} (${index + 1} of ${total})`;
        });
      }
      return docsInGroup;
    })
    .flat();

  return updatedDocs;
};

const addFormCrsDocumentIfNeeded = (documents, currentUser) => {
  if (currentUser.investor_profile.stage === 'multiply') {
    const docAlreadyAdded = documents.account_documents.some(
      doc => doc.id === 'crs_doc'
    );
    if (!docAlreadyAdded) {
      const crsDocument = {
        description: 'Notice regarding Form CRS',
        download_type: 'external_url',
        id: 'crs_doc',
        title: 'Client Relationship Summary',
        type: 'Miscellaneous',
        year: 2020,
        url: 'https://equitymultiple-production.s3.amazonaws.com/uploads/crs_document/FormCRS+Packet+2007271830.pdf'
      };
      documents.account_documents.push(crsDocument);
    }
  }
  return documents;
};

const getRows = (documents, tab, currentUser) => {
  let docs = addFormCrsDocumentIfNeeded(documents, currentUser);
  docs = docs[tab];

  return docs.map(doc => {
    // Doc URL only used for CRS doc at the moment
    const docLink = doc.url || `/mkt/documents/download?ids=${doc.id}`;
    const isAccountStatement = doc.title === 'Account Statement';
    const isCurrentYear = doc.year === new Date().getFullYear();
    let docTitle = doc.title === 'Idcard' ? 'ID Card' : doc.title;

    if (doc.type === 'Subscription Agreement' && !doc.meta?.is_complete)
      docTitle = (
        <>
          <strong>Pending Countersignature - </strong>
          {doc.title}
        </>
      );

    return {
      className: isCurrentYear ? styles.currentYear : '',
      cells: [
        '',
        <a
          key="file"
          href={docLink}
          onClick={
            isAccountStatement ? sendAccountStatementDownloadEvent : null
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="document file icon"
            src={doc.image ? jpg : pdf}
            className={styles.fileIcon}
          />
        </a>,
        {
          value: (
            <div key="title">
              <a
                className={styles.titleLink}
                href={docLink}
                onClick={
                  isAccountStatement ? sendAccountStatementDownloadEvent : null
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {docTitle}
              </a>
              <div>{doc.description}</div>
            </div>
          ),
          sortableValue: docTitle
        },
        doc.type || '',
        doc.year
      ]
    };
  });
};

interface Props {
  currentUser: User;
  dispatch: Dispatch;
  documents: LoadDocumentsResponse;
  loading: boolean;
  loadingTaxStatuses: boolean;
}

const MyDocuments = ({
  currentUser,
  dispatch,
  documents,
  loading,
  loadingTaxStatuses
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('tax_documents');
  const [listOfSelectedDocuments, setListOfSelectedDocuments] = useState([]);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    document.title = 'My Documents | EquityMultiple';

    if (!canAccessPage('documents', currentUser))
      navigate('/', { replace: true });

    dispatch(loadDocuments());
    dispatch(loadTaxOfferingStatuses());
    const query = location.search ? queryString.parse(location.search) : null;
    if (query?.tab) {
      const tab = query.tab.toString();
      if (
        ['tax_documents', 'account_documents', 'investment_documents'].includes(
          tab
        )
      )
        setActiveTab(tab);
    }
  }, [currentUser, dispatch, location.search, navigate]);

  const setSelectedDocumentsForDownload = input => {
    if (Array.isArray(input)) {
      if (listOfSelectedDocuments.length === input.length)
        setListOfSelectedDocuments([]);
      else setListOfSelectedDocuments(input);
    } else if (listOfSelectedDocuments.indexOf(input) !== -1) {
      setListOfSelectedDocuments(
        listOfSelectedDocuments.filter(ids => ids !== input)
      );
    } else {
      setListOfSelectedDocuments([...listOfSelectedDocuments, input]);
    }
  };

  const downloadDocuments = () => {
    const userName = `${currentUser.first_name} ${currentUser.last_name}`;
    if (listOfSelectedDocuments.length > 1) {
      setDownloading(true);
      fetch(`/mkt/documents/download?ids=${listOfSelectedDocuments}`)
        .then(response => response.blob())
        .then(blob => {
          utils.downloadFile(blob, `${userName} - Tax Documents.zip`);
          setDownloading(false);
        });
    } else {
      const selectedDocument = documents.tax_documents.find(
        document => document.id === listOfSelectedDocuments[0]
      );
      setDownloading(true);
      fetch(`/mkt/documents/download?ids=${selectedDocument.id}`)
        .then(response => response.blob())
        .then(blob => {
          utils.downloadFile(blob, `${selectedDocument.title}.pdf`);
          setDownloading(false);
        });
    }
  };

  const setTab = tab => {
    navigate({
      search: `?tab=${tab}`
    });
    setActiveTab(tab);
  };

  const hasDocs = documents[activeTab].length > 0;

  return loading || loadingTaxStatuses ? (
    <EMLoadingIcon />
  ) : (
    <div>
      <Container>
        <h1 className="margin-top-0">My Documents</h1>
        <div className={styles.documentsTab}>
          {documents &&
            Object.keys(documents).map(label => (
              <Button
                key={label}
                style={{ borderRadius: 0, margin: 0, height: 53 }}
                variant={activeTab === label ? 'blue' : 'white'}
                className={activeTab === label ? '' : styles.white}
                onClick={() => setTab(label)}
              >
                {docTitles[label]}
              </Button>
            ))}
        </div>
        <div>
          {activeTab === 'tax_documents' && hasDocs ? (
            <TaxDocuments
              setSelectedDocumentsForDownload={setSelectedDocumentsForDownload}
              listOfSelectedDocuments={listOfSelectedDocuments}
              documents={addCountToDuplicateDocs(documents.tax_documents)}
            />
          ) : (
            <Card className={styles.docsCard}>
              {hasDocs ? (
                <Table
                  className={styles.table}
                  columnHeaders={colNames}
                  allowSorting={[2, 3, 4]}
                  rows={getRows(documents, activeTab, currentUser)}
                />
              ) : (
                <p className={styles.noDocuments}>
                  You don&apos;t have any {activeTab.replace('_', ' ')}{' '}
                  available at this time.
                </p>
              )}
            </Card>
          )}
        </div>
      </Container>
      {listOfSelectedDocuments.length > 0 && (
        <DownloadPopUp
          downloadDocuments={downloadDocuments}
          loading={downloading}
          selectedCount={listOfSelectedDocuments.length}
        />
      )}
    </div>
  );
};

function mapStateToProps(store) {
  return {
    currentUser: store.auth.user,
    documents: store.documents.documents,
    loading: store.documents.loading,
    loadedDocuments: store.documents.loaded,
    loadErrors: store.documents.loadErrors,
    loadingTaxStatuses: store.offeringTaxStatuses.loadingOfferingTaxStatuses
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(MyDocuments);
