import React from 'react';
import {
  LoadInvestmentResponse,
  PaymentMethod
} from 'types/actions/investments';
import { BankAccount } from 'types/api/bankAccount';
import { Offering } from 'types/api/offering';
import utils from 'utilities/utils';

interface Props {
  investment: LoadInvestmentResponse;
  offering: Offering;
  selectedBalance: number;
  selectedBankAccount: BankAccount;
  type: PaymentMethod;
}

const PaymentMethodDetails = ({
  type,
  offering,
  investment,
  selectedBankAccount,
  selectedBalance
}: Props) => {
  const offeringReference = offering.stn
    ? offering.title
    : offering.entity_name;

  const remainingAmount =
    parseInt(investment.unfunded_amount) > 0
      ? parseInt(investment.unfunded_amount) - selectedBalance
      : parseInt(investment.pending_amount || '0');

  const showAlpineBalanceSelection =
    selectedBalance > 0 && (remainingAmount === 0 || (type && type !== 'ACH'));

  const showEscrowInfo = type && (type === 'ACH' ? selectedBankAccount : true);

  return (
    <div className="margin-top-xx">
      {showAlpineBalanceSelection && (
        <p className="margin-x">
          By clicking "Complete", you authorize us to transfer{' '}
          {utils.formatCurrency(selectedBalance)} from your Alpine Note
          Principal Balance.
        </p>
      )}

      {type === 'ACH' && selectedBankAccount && (
        <div className="margin-x">
          By clicking "Complete", you authorize us to transfer{' '}
          {selectedBalance > 0
            ? `${utils.formatCurrency(selectedBalance)} from your Alpine Note
          Principal Balance and `
            : ''}
          {utils.formatCurrency(remainingAmount)} from your{' '}
          {selectedBankAccount.bank_name
            ? `${selectedBankAccount.bank_name} - ${selectedBankAccount.type}`
            : `${
                selectedBankAccount.name
              }, ending in ${selectedBankAccount.account_number.slice(-4)}`}
          . ACH transfers can take up to 5 minutes.
        </div>
      )}

      {type === 'CHECK' && offering.check_info && (
        <div className="margin-xx" data-testid="check-instructions">
          <h4>Check Instructions</h4>
          <div>
            Please note, check transfers must be sent from a bank account linked
            to <strong>{investment.investment_account.entity_name}</strong>.
            Please reference <strong>{offeringReference}</strong> in the memo
            section on the check.
          </div>
          <div className="margin-top-x">
            <p>
              Payee Name:
              <strong> {offering.check_info.payee_name}</strong>
              <br />
              Mailing Address:
              <strong> {offering.check_info.address}</strong>
            </p>
          </div>
        </div>
      )}

      {type === 'WIRE' && offering.wire_info && (
        <div className="margin-xx" data-testid="wire-instructions">
          <h4>Wire Instructions</h4>
          <div>
            Please note, wire transfers must be sent from a bank account linked
            to <strong>{investment.investment_account.entity_name}</strong>.
            Please reference <strong>{offeringReference}</strong> on the wire
            transfer.
          </div>
          <div className="margin-top-x">
            <p>
              Bank Name:
              <strong> {offering.wire_info.bank_name}</strong>
              <br />
              Bank Address:
              <strong> {offering.wire_info.bank_address}</strong>
              <br />
              Account Name:
              <strong> {offering.wire_info.account_name}</strong>
              <br />
              Account Number:
              <strong> {offering.wire_info.account}</strong>
              <br />
              Routing Number:
              <strong> {offering.wire_info.wire_routing}</strong>
              <br />
              Entity Address:
              <strong> 91 5th Ave. Floor 5 New York, NY 10003</strong>
            </p>
          </div>
        </div>
      )}
      {(showAlpineBalanceSelection || showEscrowInfo) && (
        <p className={'margin-top-x'} data-testid="escrow-info">
          Your funds are held in an FDIC insured segregated account with Bank of
          America pending closing of the underlying investment.
        </p>
      )}
    </div>
  );
};

export default PaymentMethodDetails;
