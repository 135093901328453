import {
  LoadMarketingDataResponse,
  LoadOfferingsResponse
} from 'types/actions/invest';
import { AsyncActionCreator } from 'types/redux';

export const LOAD_OFFERINGS = 'invest/LOAD_OFFERINGS';
export const LOAD_OFFERINGS_SUCCESS = 'invest/LOAD_OFFERINGS_SUCCESS';
export const LOAD_OFFERINGS_FAIL = 'invest/LOAD_OFFERINGS_FAIL';

export const LOAD_MARKETING_DATA = 'invest/LOAD_MARKETING_DATA';
export const LOAD_MARKETING_DATA_SUCCESS = 'invest/LOAD_MARKETING_DATA_SUCCESS';
export const LOAD_MARKETING_DATA_FAIL = 'invest/LOAD_MARKETING_DATA_FAIL';

export function loadOfferings(): AsyncActionCreator<LoadOfferingsResponse> {
  return {
    types: [LOAD_OFFERINGS, LOAD_OFFERINGS_SUCCESS, LOAD_OFFERINGS_FAIL],
    promise: client => client.get<LoadOfferingsResponse>('/mkt/offerings')
  };
}

export function loadMarketingData(): AsyncActionCreator<LoadMarketingDataResponse> {
  return {
    types: [
      LOAD_MARKETING_DATA,
      LOAD_MARKETING_DATA_SUCCESS,
      LOAD_MARKETING_DATA_FAIL
    ],
    promise: client =>
      client.get<LoadMarketingDataResponse>(
        process.env.REACT_APP_WP_CONFIG_PAGE_ACF_URL ||
          'https://cms.eqm-int.com/wp-json/acf/v3/pages/42363/',
        {
          excludeToken: true
        }
      )
  };
}
