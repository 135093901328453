import { Button } from '@equitymultiple/react-eui';
import Back from 'images/icons/arrow-back.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { InvestmentAccountAccountType } from 'types/actions/account';

import { isComplete, supportsBeneficialOwners } from '../../helpers';

type PageName =
  | 'Joint Account Identification'
  | 'Identification Document'
  | 'Organizational Document'
  | 'POA Document'
  | 'Tax Document'
  | 'W9 Document'
  | 'Bank Accounts'
  | 'Beneficial Owners'
  | 'New Beneficial Owner'
  | 'Edit Beneficial Owner'
  | 'Entity'
  | 'Secondary Holder';

interface Props {
  investmentAccount?: {
    account_type: InvestmentAccountAccountType;
    reference_id: number;
  };
  page?: PageName;
  route?: string;
}

const BackLink = ({ investmentAccount, page, route }: Props) => {
  const getLink = () => {
    if (route) return route;

    if (!investmentAccount) return '/accounts';

    const accountType = investmentAccount?.account_type;
    const referenceId = investmentAccount?.reference_id;

    const baseRoute = `/accounts/${
      isComplete(investmentAccount) ? 'update' : 'wizard'
    }/${accountType}/`;
    let subRoute = '';

    if (isComplete(investmentAccount)) {
      // Default to the account page for completed accounts unless the page is specified below
      subRoute = '';
      switch (page) {
        case 'Joint Account Identification':
        case 'Identification Document':
        case 'Organizational Document':
        case 'POA Document':
        case 'Tax Document':
        case 'W9 Document':
          subRoute = 'documents/';
      }
    } else {
      // Go to the previous step if the account is incomplete
      switch (page) {
        case 'Bank Accounts':
          subRoute = accountType === 'joint' ? 'poa/' : 'w9/';
          break;
        case 'Beneficial Owners':
          subRoute = 'tax_document/';
          break;
        case 'Joint Account Identification':
          subRoute = 'secondary_account/';
          break;
        case 'New Beneficial Owner':
        case 'Edit Beneficial Owner':
          subRoute = 'beneficial_owners/';
          break;
        case 'Organizational Document':
          subRoute = 'information/';
          break;
        case 'POA Document':
          subRoute = 'w9/';
          break;
        case 'Tax Document':
          subRoute = 'organizational_document/';
          break;
        case 'W9 Document':
          if (accountType === 'joint') {
            subRoute = 'secondary_account/identification_document/';
          } else if (accountType === 'entity') {
            if (supportsBeneficialOwners(investmentAccount))
              subRoute = 'beneficial_owners/';
            else subRoute = 'tax_document/';
          } else {
            return '/accounts/new';
          }
          break;
        case 'Entity':
        case 'Secondary Holder':
          return '/accounts/new';
      }
    }

    return baseRoute + subRoute + referenceId;
  };

  return (
    <Button
      variant="outlined"
      data-testid="backLink"
      className="button-fixed-width arrowBackButton"
      wrapper={<Link to={getLink()} />}
    >
      <Back />
    </Button>
  );
};

export default BackLink;
