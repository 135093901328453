import { Alert } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearAccountStatement, getDashboard } from 'redux/actions/portfolio';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';

import Contact from '../../components/Contact/Contact';
import EmAnalytics from '../../utilities/em_analytics';
import { canAccessPage } from '../../utilities/user';
import Accounts from './components/Accounts/Accounts';
import Articles from './components/Articles/Articles';
import Diversification from './components/Diversification/Diversification';
import EarningsTable from './components/EarningsTable/EarningsTable';
import Overview from './components/Overview/Overview';
import * as styles from './Portfolio.module.scss';
import { DashboardAccount, DashboardInvestment, Metrics } from './types';

interface Props {
  accountStatement: {
    filename: string;
    url: string;
  };
  dashboard: {
    accounts: DashboardAccount[];
    investments: DashboardInvestment[];
    metrics: Metrics;
  };
  dispatch: Dispatch;
  generatingAccountStatement: boolean;
  loading: boolean;
  user: User;
}

const userCanInvest = (user: User) =>
  user?.investor_profile.stage === 'invest' ||
  user?.investor_profile.stage === 'multiply';

const Portfolio = ({
  accountStatement,
  dashboard,
  dispatch,
  generatingAccountStatement,
  loading,
  user
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'My Portfolio | EquityMultiple';

    if (!canAccessPage('portfolio', user)) navigate('/', { replace: true });

    if (userCanInvest(user)) {
      dispatch(getDashboard());
    }
  }, [dispatch, user, navigate]);

  const handleAccountStatementDownload = () => {
    dispatch(clearAccountStatement());
    EmAnalytics.track('Investor Downloaded Account Statement', '', {
      page: 'Portfolio'
    });
  };

  const loadingDashboard = userCanInvest(user) && loading;
  const showDiversificationNullState =
    !loadingDashboard &&
    (!dashboard.investments || dashboard.investments.length === 0);
  const completedAccountsCount = user.created_investment_accounts_count;

  return (
    <>
      <Overview
        heading="Portfolio Overview"
        metrics={dashboard.metrics}
        loading={loadingDashboard}
        page="dashboard"
        user={user}
      />
      <Container>
        {generatingAccountStatement && (
          <Alert type="positive" className={styles.inlineMessage}>
            Hang tight, we are preparing your statement. This should take just a
            few seconds.
          </Alert>
        )}
        {accountStatement && (
          <Alert
            type="positive"
            className={styles.inlineMessage}
            buttonProps={{
              children: 'Download',
              download: accountStatement.filename,
              href: accountStatement.url,
              onClick: handleAccountStatementDownload,
              target: '_blank',
              style: { width: 160 }
            }}
          >
            Your statement is ready for download!
          </Alert>
        )}
        <Accounts
          accounts={dashboard.accounts}
          completedAccountsCount={completedAccountsCount}
          loading={loadingDashboard}
          userStage={user.investor_profile.stage}
        />
        <Row>
          <Col lg={4}>
            <EarningsTable
              earnings={dashboard.metrics.earnings}
              loading={loadingDashboard}
            />
          </Col>
          <Col lg={8}>
            <Diversification
              investments={dashboard.investments}
              loading={loading}
              showNullState={showDiversificationNullState}
            />
          </Col>
        </Row>

        <Articles />
        <Contact />
      </Container>
    </>
  );
};

function mapStateToProps(store) {
  return {
    accountStatement: store.portfolio.accountStatement,
    dashboard: store.portfolio.dashboard,
    generatingAccountStatement: store.portfolio.generatingAccountStatement,
    loading: store.portfolio.loadingDashboard,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Portfolio);
