import { yupResolver } from '@hookform/resolvers/yup';
import { isComplete } from 'containers/Accounts/helpers';
import { AccountHolder2Fields } from 'containers/Accounts/types';
import { jointSecondaryHolderSchema } from 'containers/Accounts/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  JointInvestmentAccount,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import AccountWrap from '../AccountWrap/AccountWrap';
import JointSecondaryHolderFields from './JointSecondaryHolderFields';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<JointInvestmentAccount>;
  loading: boolean;
}

const JointSecondaryHolderUpdate = ({
  dispatch,
  investmentAccount,
  loading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const defaultValues = {
    investment_account: investmentAccount
      ? {
          account_holder_2_address: investmentAccount.account_holder_2_address,
          account_holder_2_address2:
            investmentAccount.account_holder_2_address2,
          account_holder_2_address_is_same:
            investmentAccount.account_holder_2_address_is_same,
          account_holder_2_city: investmentAccount.account_holder_2_city,
          account_holder_2_email: investmentAccount.account_holder_2_email,
          account_holder_2_first_name:
            investmentAccount.account_holder_2_first_name,
          account_holder_2_last_name:
            investmentAccount.account_holder_2_last_name,
          account_holder_2_phone: investmentAccount.account_holder_2_phone,
          account_holder_2_postal_code:
            investmentAccount.account_holder_2_postal_code,
          account_holder_2_region: investmentAccount.account_holder_2_region
        }
      : {
          account_holder_2_address_is_same: false
        }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    watch
  } = useForm<AccountHolder2Fields>({
    resolver: yupResolver(jointSecondaryHolderSchema),
    values: defaultValues
  });

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, [dispatch, navigate, params]);

  const sameAddress = watch(
    'investment_account.account_holder_2_address_is_same'
  );

  const onSubmit = values => {
    values.investment_account.id = investmentAccount.id;
    return dispatch(updateInvestmentAccount(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          navigate(`/accounts/update/joint/${investmentAccount.reference_id}`);
        } else {
          navigate(
            `/accounts/wizard/joint/secondary_account/identification_document/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(error => throwReactHookFormSubmissionErrors(error, setError));
  };

  return (
    <AccountWrap loading={loading} account={investmentAccount}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <JointSecondaryHolderFields
          investmentAccount={investmentAccount}
          sameAddress={sameAddress}
          submitting={isSubmitting}
          control={control}
          errors={errors}
        />
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(JointSecondaryHolderUpdate);
