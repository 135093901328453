import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import PhoneFields, {
  PhoneFormFields
} from 'components/forms/PhoneFields/PhoneFields';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { requestPhoneVerificationCode } from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { phoneSchema } from '../validation';

export interface Props {
  dispatch: Dispatch;
  onSubmitSuccess: (values: PhoneFormFields) => void;
  user: User;
}

const PhoneForm = ({ dispatch, onSubmitSuccess, user }: Props) => {
  const defaultValues = {
    country_code: user.country_code || '1',
    phone: user.phone
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<PhoneFormFields>({
    resolver: yupResolver(phoneSchema),
    defaultValues
  });

  const onSubmit = async values => {
    const token = await getRecaptchaToken('requestSmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        phone: values.phone,
        country_code: values.country_code
      }
    };

    return dispatch(requestPhoneVerificationCode(submitValues))
      .then(() => onSubmitSuccess(values))
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="margin-xx">
        Enter the phone number you'll use to receive one-time passwords:
      </h5>
      <PhoneFields<PhoneFormFields> control={control} errors={errors} />
      <FormError errors={errors} />
      <Button style={{ width: 300 }} type="submit" loading={isSubmitting}>
        Send One-Time Password
      </Button>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(PhoneForm);
