import { Button, DocumentIframe, FileIcon } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkDocumentSignStatus,
  generatePOADocument,
  loadInvestmentAccount
} from 'redux/actions/account';
import {
  JointInvestmentAccount,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import utils from 'utilities/utils';

import * as sharedStyles from '../AccountShared.module.scss';
import { isComplete } from '../helpers';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<JointInvestmentAccount>;
}

const PoaDocumentUpdate = ({ dispatch, investmentAccount }: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [loadingPoa, setLoadingPoa] = useState(true);
  const [generatingPoa, setGeneratingPoa] = useState(false);
  const [poaAgreementSigned, setPoaAgreementSigned] = useState(false);
  const [poaDownloadUrl, setPoaDownloadUrl] = useState('');
  const [poaAgreementSigningUrl, setPoaAgreementSigningUrl] = useState('');

  const loadSignedPoa = () => {
    dispatch(
      checkDocumentSignStatus(params.reference_id, 'poa_agreement')
    ).then(doc => {
      const signStatus = {
        poaAgreementSigned: false,
        poaAgreementSigningUrl: null,
        poaDownloadUrl: null,
        loadingPoa: false
      };

      signStatus.poaAgreementSigned = !!(
        doc.status &&
        (doc.status === 'Complete' || doc.status === 'In Progress')
      );

      if (doc.signing_url?.['Primary Account Holder'])
        signStatus.poaAgreementSigningUrl =
          doc.signing_url['Primary Account Holder'];

      if (doc.download_url) signStatus.poaDownloadUrl = doc.download_url;

      if (
        !signStatus.poaAgreementSigned &&
        !signStatus.poaAgreementSigningUrl &&
        !generatingPoa
      ) {
        setGeneratingPoa(true);
        dispatch(
          generatePOADocument(investmentAccount.user_id, investmentAccount.id)
        );
      }

      setPoaAgreementSigned(signStatus.poaAgreementSigned);
      setPoaAgreementSigningUrl(signStatus.poaAgreementSigningUrl);
      setPoaDownloadUrl(signStatus.poaDownloadUrl);
      setLoadingPoa(signStatus.loadingPoa);
    });
  };

  useEffect(() => {
    document.title = 'My Accounts | Sign Power of Attorney';

    let checkDocumentInterval;

    dispatch(loadInvestmentAccount(params.reference_id))
      .then(res => {
        if (res?.investment_account) {
          loadSignedPoa();
          checkDocumentInterval = setInterval(loadSignedPoa, 5000);
        }
      })
      .catch(error => handleErrorResponse(navigate, error));

    return () => {
      clearInterval(checkDocumentInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContinue = () => {
    if (isComplete(investmentAccount)) {
      navigate(
        `/accounts/update/${investmentAccount.account_type}/documents/${params.reference_id}`
      );
    } else {
      EmAnalytics.track(
        'Submits Joint Account Sign Power of Attorney',
        'Onboarding',
        {
          account_type: utils.startCase(investmentAccount.account_type)
        }
      );
      navigate(
        `/accounts/wizard/${investmentAccount.account_type}/link_bank/${params.reference_id}`
      );
    }
  };

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">
            Please sign power of attorney document
          </h4>
          <p className="margin-xx">
            Before you can invest through this Joint Account both account
            holders must sign a Limited Power of Attorney that enables the
            primary account holder to make decisions and execute agreements
            relating to investments on the EquityMultiple platform. Please
            review and sign the agreement below. Once signed, an e-mail will be
            sent to the secondary account holder with a link to sign the
            agreement electronically.
          </p>
          {investmentAccount.poa_agreement_status !== 'Complete' && (
            <p className="margin-xx">
              The e-mail will be sent to the following e-mail address provided
              for the second account holder:{' '}
              <b>{investmentAccount.account_holder_2_email}</b>
            </p>
          )}
          {poaAgreementSigned && poaDownloadUrl && (
            <div className="margin-x">
              {loadingPoa ? (
                <p>Loading poa...</p>
              ) : (
                <div>
                  <FileIcon
                    url="agreement.pdf"
                    className={sharedStyles.docIcon}
                  />
                  <a
                    href={poaDownloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={sharedStyles.docLink}
                  >
                    Current POA
                  </a>
                </div>
              )}
            </div>
          )}
          {investmentAccount.poa_agreement_status === 'In Progress' && (
            <Row className="margin-row">
              <Col md={12} className="text-center">
                <h6>
                  The Power of Attorney letter has not been counter-signed.
                </h6>
                <p className="margin-xx">
                  To be able to view this letter, please ensure that this letter
                  is signed by all parties.
                </p>
              </Col>
            </Row>
          )}
          {poaAgreementSigningUrl && !poaAgreementSigned && (
            <DocumentIframe
              src={poaAgreementSigningUrl}
              title="Sign Power of Attorney"
            />
          )}

          <div className="forwardBackButtonWrapCompact">
            <Button
              disabled={!poaAgreementSigned}
              onClick={handleContinue}
              style={{ width: 160 }}
              variant="orange"
            >
              {isComplete(investmentAccount) ? 'Back' : 'Continue'}
            </Button>
            <BackLink
              investmentAccount={investmentAccount}
              page="POA Document"
            />
          </div>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(PoaDocumentUpdate);
