import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createBeneficialOwner,
  loadInvestmentAccount
} from 'redux/actions/account';
import {
  BeneficialOwner,
  EntityInvestmentAccount,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { isComplete } from '../helpers';
import { BeneficialOwnerFields as BeneficialOwnerFieldsType } from '../types';
import { beneficialOwnerSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import BeneficialOwnerFields from './BeneficialOwnerFields';
import BeneficialOwnerDocumentFields from './BeneficialOwnersDocumentFields';

type Params = {
  reference_id: string;
};

interface Props {
  beneficialOwners: BeneficialOwner[];
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<EntityInvestmentAccount>;
}

const BeneficialNew = ({
  dispatch,
  investmentAccount,
  beneficialOwners
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const defaultValues = {
    beneficial_owner: {
      beneficialOwners
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue
  } = useForm<BeneficialOwnerFieldsType>({
    resolver: yupResolver(beneficialOwnerSchema),
    values: defaultValues
  });

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, [dispatch, params, navigate]);

  const onSubmit = values => {
    return dispatch(createBeneficialOwner(params.reference_id, values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          navigate(
            `/accounts/update/${investmentAccount.account_type}/${params.reference_id}`
          );
        } else {
          navigate(
            `/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/${params.reference_id}`
          );
        }
      })
      .catch(error => throwReactHookFormSubmissionErrors(error, setError));
  };

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">Add Beneficial Owner</h4>
          <p className="margin-xx">
            In order to comply with Know Your Customer (KYC) and Anti-Money
            Laundering (AML) rules and policies, we are required to collect
            additional information on every beneficial owner who owns 25% or
            more of your entity.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BeneficialOwnerFields
              control={control}
              errors={errors}
              hasSsn={false}
            />
            <h5>Identification Document</h5>
            <BeneficialOwnerDocumentFields
              control={control}
              errors={errors}
              setValue={setValue}
              uploading={isSubmitting}
            />

            <FormError errors={errors} />

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                style={{ width: 160 }}
                loading={isSubmitting}
              >
                Add
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="New Beneficial Owner"
              />
            </div>
          </form>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    beneficialOwners: state.account.investmentAccount?.beneficial_owners
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(BeneficialNew);
