import { Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressFormFields } from 'containers/UserUpdateAlerts/types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { User } from 'types/actions/auth';
import { setFieldProps } from 'utilities/formHelpers';
import { phoneMask } from 'utilities/masks';

import AddressForm from './AddressForm';
import { personalAddressVerificationSchema } from './validation';

interface Props {
  investmentAccountTitle: string;
  onSubmit: (values: AddressFormFields) => void;
  previousWizard: () => void;
  previouslyEnteredUpdatedUserInformation: Partial<User>;
  user: User;
}

const PersonalAccountAddressVerificationForm = ({
  previousWizard,
  investmentAccountTitle,
  onSubmit,
  previouslyEnteredUpdatedUserInformation,
  user
}: Props) => {
  const accountDetails = previouslyEnteredUpdatedUserInformation || user;

  const defaultValues = {
    user_address_verification: {
      ...accountDetails
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<AddressFormFields>({
    values: defaultValues,
    resolver: yupResolver(personalAddressVerificationSchema)
  });

  const formValues = watch();

  const fullName = `${formValues?.user_address_verification.first_name} ${formValues?.user_address_verification.last_name}`;

  return (
    <>
      <div>
        <h4 className="margin-x">{fullName} Contact Information</h4>
        <div className="margin-xx size-16">
          To comply with securities regulations and to prepare your tax
          documents, we are required to gather up-to-date information on your{' '}
          {investmentAccountTitle}.
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="margin-xx">Update:</h6>
        <Row className="margin-row">
          <Col md={6}>
            <Controller
              name="user_address_verification.phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Phone Number"
                  mask={phoneMask}
                />
              )}
            />
          </Col>
        </Row>
        <AddressForm
          formName="user_address_verification.address"
          previousWizard={previousWizard}
          control={control}
          errors={errors}
        />
      </form>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(PersonalAccountAddressVerificationForm);
